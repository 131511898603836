.card {
  margin: 20px;
  width: 18rem;
  border-radius: 10px;
  box-shadow: 10px 10px 4px #111;
  border: 3px solid #545455;
}

.card-title {
  margin-bottom: 15px;
}

#closeIcon {
  display: flex;
  justify-content: flex-end;
  margin-right: 8px;
  margin-top: 8px;
}

.card-body {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
