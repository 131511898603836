.ciudad {
    margin: auto;
    margin-top: 20px;
    width: 28rem;
    border-radius: 10px;
    box-shadow: 10px 10px 4px #111;
    border: 3px solid #545455;
    background-color: #f2f2f2;
    padding: 10px;
    justify-content: center;
  }
  .ciudad-title {
    margin-bottom: 15px;
  }
  
  #closeIcon {
    display: flex;
    justify-content: flex-end;
    margin-right: 8px;
    margin-top: 8px;
  }
  
  .ciudad-body {
    margin-top: 0px !important;
 
    padding-top: 0px !important;
   
  }